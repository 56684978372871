<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.path"
    :exact-active-class="!isChild ? 'primary white--text': 'child-nav-item'"
    exact
  >
    <v-list-item-content
      v-if="item.title || item.subtitle"
      class="text-start"
    >
      <v-icon
        v-if="drawer"
        v-html='item.meta.icon'
      />
      <v-list-item-title
        v-else
      >
        <v-icon
          v-if="item.meta.icon"
          v-html='item.meta.icon'
          class="mr-2"
        />
        {{item.title}}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from 'vuetify/lib/mixins/themeable'
import { mapState } from 'vuex'

export default {
  name: 'Item',

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        path: undefined
      })
    },
    text: {
      type: Boolean,
      default: false
    },
    isChild: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(['drawer']),
    computedText () {
      if (!this.item || !this.item.title) return ''

      let text = ''

      this.item.title.split(' ').forEach(val => {
        text += val.substring(0, 1)
      })

      return text
    },
    href () {
      return this.item.href || (!this.item.path ? '#' : undefined)
    }
  }
}
</script>
